import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
// import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import { Icon } from 'react-icons-kit';
import { filePdf } from 'react-icons-kit/icomoon/filePdf';
import SocialShare from '../SocialShare';

import Container from '../../components/UI/Container';
import FeatureBlock from '../../components/FeatureBlock';
import Breadcrumbs from '../Breadcrumbs';
import ImageGallery from './ImageGallery';
import ProductImage from './ProductImage';

import ProductBannerWrapper, { ProductInfoWrapper } from './product-banner.style';

const BannerSection = ({ node, topCol, row, col, textArea, imageArea }) => {
  if (!node || !node.frontmatter) {
    return null;
  }
  const {
    // sku,
    title,
    catalog = [],
    // description,
    slug,
    cover,
    manual_pdf,
    slides
  } = node.frontmatter;

  const [_, productCatalog] = catalog[0].split('/');
  const catalogPath = productCatalog
    ? {
        order: 2,
        title: productCatalog.charAt(0).toUpperCase() + productCatalog.slice(1),
        slug: `/catalogo/${productCatalog}`
      }
    : {};
  const multiCatalog = catalog.length > 1;
  const singlePath = [
    {
      order: 1,
      title: 'Catálogo',
      slug: '/catalogo'
    },
    catalogPath,
    {
      order: 3,
      title,
      slug: `/product/${slug}`
    }
  ];
  const multiPath = catalog.map((cat, index) => {
    const [_, catalogName] = cat.split('/');
    return {
      order: index + 1,
      title: catalogName.charAt(0).toUpperCase() + catalogName.slice(1),
      slug: `/catalogo/${catalogName}`
    };
  });

  const PATHS = multiCatalog ? multiPath : singlePath;

  const showSlider = slides && slides.length > 1;
  const SlideImages =
    slides &&
    slides.map((slide, index) => ({
      src: slide,
      alt: title,
      key: `${title}-${index}`
    }));

  return (
    <ProductBannerWrapper>
      <Container>
        <Box flexBox justifyContent="space-between" {...row}>
          <Box {...topCol}>
            <Breadcrumbs paths={PATHS} pills={multiCatalog} />
          </Box>
        </Box>

        <Box {...row}>
          <Box {...col} {...textArea} className="product__block">
            <Box>
              <FeatureBlock
                button={(
                  <SocialShare
                    node={node}
                    transparent
                    color="textColor"
                    prefix="product"
                    size="sm"
                  />
)}
              />
            </Box>
            <Heading as="h1" content={title} />
            {/* <Text content={description} /> */}
            <ProductInfoWrapper>
              <div dangerouslySetInnerHTML={{ __html: node.html }} />
              <Box {...row} className="product__specs">
                {manual_pdf && (
                  <Box>
                    <a href={manual_pdf} target="_blank" rel="noopener noreferrer">
                      <Button
                        icon={<Icon icon={filePdf} size={32} />}
                        iconPosition="left"
                        title="Ficha información"
                        variant="textButton"
                      />
                    </a>
                  </Box>
                )}
              </Box>
            </ProductInfoWrapper>
          </Box>
          <Box {...col} {...imageArea} className="images_col">
            {showSlider ? (
              <ImageGallery items={SlideImages} className="feature__images" />
            ) : (
              <ProductImage src={slides ? slides[0] : cover} alt={title} />
            )}
          </Box>
        </Box>
        <div id="sticky-stop" className="sticky-stop-style" />
        <Box>
          <Container className="center-cta">
            <div className="cta-btn">
              <Link to={`/contacto?product=${slug}`}>
                <Button title="Contactanos" />
              </Link>
            </div>
            <div className="cta-details">
              Para más información, también puede revisar
              <br />
              <Link to="/preguntas-frecuentes">preguntas frecuentes</Link>
            </div>
          </Container>
        </Box>
      </Container>
    </ProductBannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  topCol: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  col: {
    pr: '15px',
    pl: '15px'
  },
  topCol: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '50%', '50%']
  },
  textArea: {
    width: ['100%', '100%', '55%', '50%', '50%']
  },
  imageArea: {
    width: ['100%', '100%', '45%', '50%', '50%'],
    mb: ['40px', '40px', '0', '0', '0'],
    mt: ['40px', '40px', '0', '0', '0']
  }
};

export default BannerSection;
