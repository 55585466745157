import styled from 'styled-components';

const ProductDetailsWrapper = styled.div`
  margin-top: 2rem;
`;

export const DetailBlockWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${'' /* padding: 1rem 0; */}
  .img-box {
    width: 50%;
    min-height: 45vh;
    max-height: 75vh;
    img {
      ${'' /* padding: 1.5rem; */}
      height: 100%;
      width: 100%;
      display: block;
      object-fit: contain;
      border: 0;
    }
    @media (max-width: 768px) {
      width: 100%;
      max-height: 450px;
    }
  }
  .detail-box {
    align-items: center;
    display: flex;
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
    min-height: 40vh;
    .container {
      padding: 5rem 10rem;
      @media only screen and (max-width: 991px) {
        padding: 2rem 5rem;
      }
      @media only screen and (max-width: 667px) {
        padding: 2rem;
      }
    }
    .detail-header {
      font-size: 0.85rem;
      text-transform: uppercase;
      margin-bottom: 1.5rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.labelColor};
    }
    .detail-title {
      color: ${({ theme }) => theme.colors.headingColor};
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
      font-weight: 500;
    }
    .detail-body {
      color: ${({ theme }) => theme.colors.textColor};
      font-size: 1rem;
      margin-top: 20px;
      line-height: 1.5;
    }
  }
`;

export default ProductDetailsWrapper;
