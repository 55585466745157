import styled from 'styled-components';

const ProductTableWrapper = styled.div`
  margin-top: 3rem;
  padding-bottom: 1rem;
  .text-center {
    text-align: center;
  }
  h2,
  h3 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.headingColor};
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 0;
    @media only screen and (max-width: 574px) {
      font-size: 1.8rem;
    }
    span {
      font-weight: 700;
    }
  }
`;

export const TableWrapper = styled.div`
  margin-top: 1rem;
  .product-table {
    display: flex;
    justify-content: center;
    padding: 1rem;
    @media (max-width: 574px) {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
  }
  table {
    color: ${({ theme }) => theme.colors.labelColor};
    border-spacing: 0px;
    thead {
      border-bottom: 1px solid;
      text-transform: uppercase;
    }
    tr {
      font-weight: 600;
    }
    th {
      padding-bottom: 0.35rem;
      min-width: 115px;
      font-size: 0.85rem;
      border-bottom: 2px solid ${({ theme }) => theme.colors.labelColor};
    }
    td {
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
      font-size: 0.85rem;
      font-weight: 400;
    }
  }
`;

export default ProductTableWrapper;
