import styled from 'styled-components';

const ProductBannerWrapper = styled.section`
  padding-top: 125px;
  ${'' /* padding-bottom: 200px; */}
  ${'' /* @media (max-width: 1440px) {
    padding-bottom: 200px;
  } */}
  @media (max-width: 990px) {
    padding-top: 150px;
    ${'' /* padding-bottom: 150px; */}
  }
  @media (max-width: 768px) {
    padding-top: 150px;
    ${'' /* padding-bottom: 125px; */}
    ${'' /* .feature__images {
      text-align: center;
      justify-content: center;
      display: flex;
    } */}
  }
  @media only screen and (max-width: 480px) {
    padding-top: 130px;
    ${'' /* padding-bottom: 100px; */}
  }
  .sticky-stop-style {
    margin-bottom: 2rem;
  }
  .button__wrapper {
    margin-bottom: 10px;
    display: flex;
    .reusecore__button {
      border-radius: 6px;
      &:first-child {
        transition: all 0.3s ease;
        ${'' /* margin-right: 30px; */}
        @media (max-width: 990px) {
          margin-right: 0;
        }
        ${'' /* &:hover {
          box-shadow: ${({ theme }) => theme.colors.primaryBoxShadow}
        } */}
      }
      &:nth-child(2) {
        padding-left: 0;
        @media (max-width: 1100px) {
          padding-left: 15px;
        }

        @media (max-width: 480px) {
          padding-left: 0;
          padding-top: 15px;
        }
        margin-top: 15px;
        .btn-text {
          font-weight: 700;
          margin-top: 7px;
        }
        .btn-icon {
          margin-top: 6px;
          margin-left: 6px;
        }
      }
      > a {
        font-weight: 700;
        color: #fff;
      }
    }
    .btnWithoutColor {
      margin-right: 15px !important;
      padding: 5px 0px !important;
      .btn-icon {
        svg {
          width: 30px;
          height: 30px;
        }
      }
      &:hover {
        box-shadow: none !important;
      }
    }
  }
  .product__specs {
    margin-top: 2rem;
    span {
      margin-left: 10px;
    }
    button {
      ${'' /* color: ${({ theme }) => theme.colors.blue}; */}
      color: #e34a37;
    }
  }
  .product__block {
    padding-left: 2rem;
    @media (max-width: 768px) {
      padding: 2rem;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-weight: 500;
      color: ${({ theme }) => theme.colors.headingColor};
      letter-spacing: -0.025em;
      line-height: 1.2;
      margin-bottom: 25px;
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
    h1 {
      font-size: 4.5rem;
      @media (max-width: 1440px) {
        font-size: 3.85rem;
      }
      @media (max-width: 990px) {
        font-size: 3.5rem;
      }
      @media (max-width: 768px) {
        font-size: 3rem;
      }
      @media (max-width: 572px) {
        font-size: 2.5rem;
      }
    }
    p {
      color: ${({ theme }) => theme.colors.textColor};
      line-height: 24px;
      margin-bottom: 20px;
      max-width: 550px;
      font-size: 1rem;
    }
  }
  .center-cta {
    text-align: center;
    ${'' /* margin-top: 0rem; */}
    .cta-btn {
      padding: 1rem 0;
      button {
        width: 50%;
        @media only screen and (max-width: 767px) {
          width: 80%;
        }
      }
    }
    .cta-details {
      color: ${({ theme }) => theme.colors.labelColor};
      font-size: 0.8rem;
      line-height: 1.5;
      a {
        color: ${({ theme }) => theme.colors.lightBlue};
        font-weight: 500;
      }
    }
  }
`;

export const ProductInfoWrapper = styled.div`
  padding-bottom: 80px;
  p {
    color: ${({ theme }) => theme.colors.textColor};
    line-height: 24px;
    margin-bottom: 20px;
    max-width: 550px;
    font-size: 1rem;
    padding-right: 5rem;
    @media (max-width: 768px) {
      padding-right: 0;
    }
  }
  strong {
    color: ${({ theme }) => theme.colors.headingColor};
  }
  hr {
    border-color: ${({ theme }) => theme.colors.baseLayer};
    width: 80%;
  }
  .product_pics {
    justify-content: center;
    display: flex;
    align-items: center;
  }
`;

export default ProductBannerWrapper;
