import React from 'react';
import Sticky from 'react-stickynode';
import Image from 'reusecore/src/elements/Image';
import ImageGalleryWrapper from './image-gallery.styles';

const ProductImage = ({ src, alt, ...props }) => {
  return (
    <ImageGalleryWrapper {...props}>
      <Sticky
        top={90}
        innerZ={100}
        activeClass="sticky-header-active"
        bottomBoundary="#sticky-stop"
      >
        <Image
          src={src}
          alt={`UpSolar ${alt}`}
          className=""
          ml="auto"
          mr="auto"
        />
        <div className="image-warning">
          *Imágenes de referencia. Productos no incluyen paneles solares.
        </div>
      </Sticky>
    </ImageGalleryWrapper>
  );
};

export default ProductImage;
