import styled, { css } from 'styled-components';

const BreadcrumbsWrapper = styled.div`
  display: inline-block;
  border-radius: 20px;
  ${'' /* border: 1px solid ${({ theme }) => theme.colors.inactiveIcon}; */}
  padding: 10px 25px;
  ${'' /* box-shadow: ${({ theme }) => theme.colors.baseBoxShadow}; */}
  margin-bottom: 30px;
  background-color: ${({ theme }) => theme.colors.background};
  @media (max-width: 767px) {
    padding: 7px 15px;
  }
  @media (max-width: 767px) {
    padding: 7px 15px;
  }
  @media (max-width: 480px) {
    ${'' /* font-size: 7px; */}
    padding: 5px 10px;
    span {
      font-size: 12px;
    }
  }
  a {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.lightBlue};
    @media (max-width: 767px) {
      font-size: 13px;
    }
  }
  .separator {
    font-size: 13px;
    color: ${({ theme }) => theme.colors.textColor};
    margin-bottom: 0;
    font-weight: 500;
    margin-right: 5px;
    margin-left: 5px;
  }
  .current-path {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.textColor};
    margin-right: 0.4em;
  }
  ${({ pill }) =>
    pill &&
    css`
      margin-right: 5px;
      padding: 10px 20px;
    `}
`;

export const TopLabel = styled.div`
  color: ${({ theme }) => theme.colors.labelColor};
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0.5rem;
  margin-left: 10px;
`;
export default BreadcrumbsWrapper;
