import React, { Fragment, Component } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';

import Navbar from 'common/source/containers/SolarNavbar';
import Footer from 'common/source/containers/Footer';
import ProductBanner from 'common/source/containers/ProductBanner';
import ProductTable from 'common/source/containers/ProductTable';
// import ProductFeatures from 'common/source/containers/ProductFeatures';
import ProductDetails from 'common/source/containers/ProductDetails';
import RelatedProducts from 'common/source/containers/RelatedProducts';
import CTABanner from 'common/source/containers/CTABanner';

import SEO from '../components/SEO';

class ProductTemplate extends Component {
  render() {
    const { pageContext, data } = this.props;
    const { slug } = pageContext;
    const { product, relatedProducts } = data;
    const post = product.frontmatter;
    if (!post.id) {
      post.id = slug;
    }
    const moreProducts = relatedProducts.edges.map(edge => edge.node);
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO postPath={slug} postNode={product} postSEO prefix="product" />
          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Navbar background="blue" />
            <ProductBanner node={product} />
            <ProductTable table={product.frontmatter.table} />
            {/* <ProductFeatures node={product} /> */}
            <ProductDetails node={product} />
            <RelatedProducts
              heading="Productos Similares"
              subtitle="Revisa algunos productos similares:"
              related={moreProducts}
            />
            <CTABanner />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default ProductTemplate;
/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query ProductBySlug($slug: String!, $related: [String]) {
    product: markdownRemark(
      frontmatter: { slug: { eq: $slug }, type: { eq: "PRODUCT" }, published: { eq: true } }
    ) {
      html
      excerpt
      fields {
        slug
      }
      frontmatter {
        sku
        title
        slug
        type
        storage
        description
        catalog
        tags
        featured
        price
        price_ref
        cover
        thumbnail
        slides
        manual_pdf
        certs {
          IP67
          IP65
          IK08
          IK10
          CE
          ROHS
          SASO
          IEC
        }
        features {
          charge
          use
          timer
          sensor
          control
          remote
        }
        table {
          label
          data {
            value
          }
        }
        details {
          img
          header
          title
          body
        }
        related
      }
    }
    relatedProducts: allMarkdownRemark(
      filter: {
        frontmatter: { sku: { in: $related }, type: { eq: "PRODUCT" }, published: { eq: true } }
      }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            sku
            title
            slug
            description
            storage
            thumbnail
            cover
          }
        }
      }
    }
  }
`;
