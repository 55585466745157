import React from 'react';
import Container from 'common/source/components/UI/Container';
import ProductTableWrapper, { TableWrapper } from './table.style';

const ProductTable = ({ table = [] }) => {
  const labels = table.map(item => item.label);
  const points = table.map(item => item.data);
  const models = points[0] ? [...Array(points[0].length)] : [];
  // if not models, return early
  if (models.length < 1) {
    return null;
  }

  const cases = models.reduce((acc, current, index) => {
    const casePoint = points.map(point => point[index]);
    return [...acc, casePoint];
  }, []);

  return (
    <ProductTableWrapper>
      <Container>
        <div className="text-center">
          <h2>Datos Generales</h2>
        </div>
      </Container>
      <TableWrapper>
        <div className="product-table">
          <table>
            <thead>
              <tr>
                {labels.map(label => {
                  return (
                    <th className="text-center" key={label}>
                      {label}
                    </th>
                  );
                })}
              </tr>
            </thead>
            {cases.length > 0 && (
              <tbody>
                {cases.map(model => {
                  const rowKey = `${model[0].value}-${model[1].value}-${model[2].value}-row`;
                  return (
                    <tr key={rowKey}>
                      {model.map((point, index) => {
                        const currentLabel = labels[index];
                        const { value } = point;
                        const cellKey = `${currentLabel}-${value}`;
                        return (
                          <td className="text-center" key={cellKey}>
                            {value}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </TableWrapper>
    </ProductTableWrapper>
  );
};

export default ProductTable;
