import React from 'react';
import Sticky from 'react-stickynode';
import Image from 'reusecore/src/elements/Image';
import GlideCarousel from 'common/source/components/GlideCarousel';
import GlideSlide from 'common/source/components/GlideCarousel/glideSlide';
import ImageGalleryWrapper, {
  PrevButton,
  NextButton
} from './image-gallery.styles';

const ImageSlider = ({ items, ...props }) => {
  const carouselOptions = {
    type: 'carousel',
    autoplay: 6000,
    perView: 1,
    gap: 5,
    animationDuration: 400
  };

  return (
    <ImageGalleryWrapper {...props}>
      <Sticky
        top={90}
        innerZ={100}
        activeClass="sticky-header-active"
        bottomBoundary="#sticky-stop"
      >
        <GlideCarousel
          carouselSelector="product-carousel"
          options={carouselOptions}
          // progress
          prevButton={
            <PrevButton>
              <span />
            </PrevButton>
          }
          nextButton={
            <NextButton>
              <span />
            </NextButton>
          }
        >
          <>
            {items.map(({ key, src, alt }) => (
              <GlideSlide key={`product-item-${key}`}>
                <Image
                  src={src}
                  alt={`UpSolar ${alt}`}
                  className=""
                  ml="auto"
                  mr="auto"
                />
              </GlideSlide>
            ))}
          </>
        </GlideCarousel>
        <div className="image-warning">
          *Imágenes de referencia. Productos no incluyen paneles solares.
        </div>
      </Sticky>
    </ImageGalleryWrapper>
  );
};

export default ImageSlider;
