import React from 'react';
import Box from 'reusecore/src/elements/Box';
import Image from 'reusecore/src/elements/Image';
import ProductDetailsWrapper, { DetailBlockWrapper } from './details.style';
import Container from '../../components/UI/Container';

const isOdd = n => {
  return Math.abs(n % 2) === 1;
};

const ProductDetails = ({ node }) => {
  const {
    frontmatter: { details }
  } = node;

  if (!details) {
    return null;
  }

  return (
    <ProductDetailsWrapper>
      {details.map((detail, index) => {
        const isEven = !isOdd(index);
        const { header, img, title, body } = detail;
        return (
          <DetailBlockWrapper key={`key-${title}-${header}`}>
            {!isEven && (
              <Box className='img-box'>
                {img ? (
                  <Image src={img} alt={`SolarPower ${title}`} />
                ) : (
                  <div></div>
                )}
              </Box>
            )}
            <Box className='detail-box'>
              <Container>
                <div className='detail-header'>{header}</div>
                <div className='detail-title'>{title}</div>
                <p className='detail-body'>{body}</p>
              </Container>
            </Box>
            {isEven && (
              <Box className='img-box'>
                {img ? (
                  <Image src={img} alt={`SolarPower ${title}`} />
                ) : (
                  <div></div>
                )}
              </Box>
            )}
          </DetailBlockWrapper>
        );
      })}
    </ProductDetailsWrapper>
  );
};

export default ProductDetails;
