import React, { Fragment } from 'react';
import { Link } from 'gatsby';
// import Text from 'reusecore/src/elements/Text';
import BreadcrumbsWrapper, { TopLabel } from './breadcrumbs.style';

const Breadcrumbs = ({ paths, pills }) => {
  if (!paths) {
    return null;
  }
  const pathSort = paths.sort((a, b) => a.order - b.order);
  const pathsCopy = pathSort.slice(0).reverse();
  if (pills) {
    return (
      <Fragment>
        <TopLabel>Aparece en:</TopLabel>
        {pathSort.map(path => {
          return (
            <BreadcrumbsWrapper key={path.title} pill>
              <Link to={path.slug}>{path.title}</Link>
            </BreadcrumbsWrapper>
          );
        })}
      </Fragment>
    );
  }
  return (
    <BreadcrumbsWrapper>
      {pathSort.length > 0 &&
        pathSort.map((path, index) => {
          if (index >= pathSort.length - 1) {
            return null;
          }
          return (
            <Fragment key={path.title}>
              <Link to={path.slug}>{path.title}</Link>
              <span className="separator">/</span>
            </Fragment>
          );
        })}
      {pathsCopy && <span className="current-path">{pathsCopy[0].title}</span>}
    </BreadcrumbsWrapper>
  );
};

export default Breadcrumbs;
